import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/Lancelot PianoB.png"

const NotFoundPage = () => (
  <Layout logo={logo} color="#333" menu="Accueil" lien="/">
    <SEO title="Cours de Piano. Cours particuliers de piano à domicile sur Toulon et ses environs. Tout niveau, enfants-ados-adultes | 404: Not found" />
    <h1 style={{ fontSize: "4rem", textAlign: "center", marginTop: "5rem" }}>
      C'est une fausse note, il n'y a rien ici !!!
    </h1>
  </Layout>
)

export default NotFoundPage
